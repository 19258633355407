import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Modal from '@mui/material/Modal';

import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import PropTypes from 'prop-types';

const FeedbackModal = ({ open, onClose }) => {
    return (
        <Modal
            open={open}
            onClose={onClose}
        >
            <Stack direction="column"
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: { md: '50%', sm: '75%', xs: '100%' },
                    maxWidth: 640,
                    height: { sm: 640, xs: '100%' },
                    bgcolor: '#fafafa',
                    boxShadow: 24,
                    outline: 'none'
                }}
            >
                <Box sx={{ height: 50 }}>
                    <IconButton
                        aria-label="close"
                        onClick={onClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </Box>
                <Box sx={{ flexGrow: 1 }}>
                    <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSdyb2fGscXVzHJl8d5VZOL42RydHhtVc_qMu2l2RWmssWiw7w/viewform?embedded=true&hl=en"
                        width="100%" height="100%" frameBorder="0" marginHeight="0" marginWidth="0">Loading…</iframe>
                </Box>
            </Stack>
        </Modal>
    );
}

FeedbackModal.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func
};

export default FeedbackModal;
