import { fetchJson } from "../common/fetcher";

const resumeService = {
    upload: async (content) => {
        try {
            const response = await fetchJson(`${process.env.REACT_APP_API_URL}/resume`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ content }),
                credentials: 'include',
            });
            return response;
        } catch (error) {
            return { error: error.message };
        }
    },
    fileUpload: async (formData) => {
        try {
            const response = await fetchJson(`${process.env.REACT_APP_API_URL}/resume/upload`, {
                method: 'POST',
                body: formData,
                credentials: 'include',
            });
            return response;
        } catch (error) {
            return { error: error.message };
        }
    },
    get: async (resumeId) => {
        try {
            const response = await fetchJson(`${process.env.REACT_APP_API_URL}/resume/${resumeId}`, {
                method: 'GET',
                credentials: 'include',
            });
            return response;
        } catch (error) {
            return { error: error.message };
        }
    },
    list: async () => {
        try {
            const response = await fetchJson(`${process.env.REACT_APP_API_URL}/resume`, {
                method: 'GET',
                credentials: 'include',
            });
            return response;
        } catch (error) {
            return { error: error.message };
        }
    },
    delete: async (resumeId) => {
        try {
            const response = await fetchJson(`${process.env.REACT_APP_API_URL}/resume/${resumeId}`, {
                method: 'DELETE',
                credentials: 'include',
            });
            return response;
        } catch (error) {
            return { error: error.message };
        }
    }
};

export default resumeService;