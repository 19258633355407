import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        common: {
            black: '#000000',
            white: '#FFFFFF',
        },
        primary: {
            main: '#3842ff',
            light: '#E0F2FE',
        },
        secondary: {
            main: '#29D2D3',
            light: '#CFFAFE',
        },
        text: {
            primary: '#111827',
            secondary: '#1F2937',
            disabled: '#6B7280',
            hint: '#9CA3AF',
        },
        background: {
            default: '#F3F4F6',
            paper: '#FFFFFF',
        },
        neutral: {
            900: "#111827",
            800: "#1F2937",
            700: "#374151",
            600: "#4B5563",
            500: "#6B7280",
            400: "#9CA3AF",
            300: "#D1D5DB",
            200: "#E5E7EB",
            100: "#F3F4F6",
            50: "#F9FAFB",
        },
    },
    typography: {
        fontFamily: '"Source Sans 3", sans-serif',
        h1: { // 2xl
            fontFamily: '"DM Sans", sans-serif',
            fontSize: '64px',
            letterSpacing: '-0.02em',
            lineHeight: '80px',
            fontWeight: undefined,
        },
        h2: { // xl
            fontFamily: '"DM Sans", sans-serif',
            fontSize: '56px',
            letterSpacing: '-0.01em',
            lineHeight: '72px',
            fontWeight: undefined,
        },
        h3: { // lg
            fontFamily: '"DM Sans", sans-serif',
            fontSize: '48px',
            letterSpacing: '-0.01em',
            lineHeight: '60px',
            fontWeight: undefined,
        },
        h4: { // md
            fontFamily: '"DM Sans", sans-serif',
            fontSize: '36px',
            letterSpacing: '-0.01em',
            lineHeight: '48px',
            fontWeight: undefined,
        },
        h5: { // sm
            fontFamily: '"DM Sans", sans-serif',
            fontSize: '30px',
            letterSpacing: '-0.01em',
            lineHeight: '40px',
            fontWeight: undefined,
        },
        h6: { //xs
            fontFamily: '"DM Sans", sans-serif',
            fontSize: '24px',
            letterSpacing: '-0.01em',
            lineHeight: '32px',
            fontWeight: undefined,
        },
        subtitle1: {
            fontFamily: '"DM Sans", sans-serif'
        },
        subtitle2: {
            fontFamily: '"DM Sans", sans-serif'
        },
        'body-xl': {
            fontSize: '20px',
            letterSpacing: '0.01em',
            lineHeight: '28px',
        },
        'body-lg': {
            fontSize: '18px',
            letterSpacing: '0.01em',
            lineHeight: '28px',
        },
        'body-md': {
            fontSize: '16px',
            letterSpacing: '0.01em',
            lineHeight: '24px',
        },
        'body-sm': {
            fontSize: '14px',
            letterSpacing: '0.01em',
            lineHeight: '20px',
        },
        'body-xs': {
            fontSize: '12px',
            letterSpacing: '0.01em',
            lineHeight: '16px',
        },
        caption: undefined
    },
    shadows: [
        'none',
        '0px 2px 8px -3px rgba(28, 44, 64, 0.16)', // xs
        '0px 4px 12px -3px rgba(28, 44, 64, 0.1)',  // sm
        '0px 8px 16px -4px rgba(28, 44, 64, 0.08)', // md
        '0px 10px 20px rgba(28, 44, 64, 0.08)',     // lg
        '4px 12px 24px rgba(28, 44, 64, 0.08)',     // xl
        '8px 16px 48px rgba(28, 44, 64, 0.12)',     // 2xl
        // Add other shadows as needed
    ],
    spacing: (factor) => `${0.25 * factor}rem`, // Default MUI spacing is 8px, adjust as needed
    // Add other theme customizations as needed
});

export default theme;