import { Container } from '@mui/material';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { useAuth } from '../auth';
import billingService from '../services/billingService';

const ThankYouPage = () => {

    const auth = useAuth();
    const navigate = useNavigate();

    useEffect(() => {

        const getUserEntitlements = async () => {

            const response = await billingService.listEntitlements();

            if (!response.error) {
                auth.setUser(user => (
                    {
                        ...user,
                        entitlements: response.entitlements
                    }
                ));
            }

        }

        getUserEntitlements();

        setTimeout(() => {
            navigate('/')
        }, 5000);

    }, []);

    return (
        <Container sx={{ width: 400, maxWidth: '100%', textAlign: 'center' }}>
            <Stack spacing={6}>
                <Typography variant="h4">Thank you for your payment!</Typography>
                <Typography variant="body">You will be taken to resume upload shortly</Typography>
                <Button component={Link} to="/">Go to resume upload</Button>
            </Stack>
        </Container>
    );

}

export default ThankYouPage;