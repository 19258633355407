import FileUploadIcon from '@mui/icons-material/FileUpload';
import LoadingButton from '@mui/lab/LoadingButton';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

const InputFileUpload = (props) => {

    const {
        name,
        title = 'Upload file',
        variant = 'contained',
        loading,
        accept,
        onClick,
        onChange
    } = props;

    return (
        <LoadingButton
            component="label"
            variant={variant}
            startIcon={<FileUploadIcon />}
            loadingPosition="start"
            loading={loading}
            onClick={onClick}
        >
            {title}
            <VisuallyHiddenInput name={name} type="file" accept={accept} onChange={onChange} />
        </LoadingButton>
    );
}

InputFileUpload.propTypes = {
    name: PropTypes.string,
    title: PropTypes.string,
    variant: PropTypes.string,
    loading: PropTypes.bool,
    accept: PropTypes.string,
    onClick: PropTypes.func,
    onChange: PropTypes.func
};

export default InputFileUpload;
