import get from 'lodash/get';
import PropTypes from 'prop-types';

import styles from './ResumeView.module.css';

const Comment = (props) => {

    const { data, path, onClick, isSelected } = props;

    const handleClick = (e, idx) => {
        onClick && onClick(e, path, idx);
    }

    const comments = get(data, path ? `${path}.comments` : 'comments');
    if (!Array.isArray(comments)) {
        return;
    }

    return comments.map((c, idx) => {
        return (
            <div
                key={idx}
                className={`${styles.comment} ${isSelected && isSelected(path, idx) ? styles.commentSelected : ''}`}
                onClick={(e) => { handleClick(e, idx) }}>
                {c.message}
                {c.correction && <div>suggested: <i>{c.correction}</i></div>}
            </div>
        )
    });

}

Comment.propTypes = {
    data: PropTypes.object,
    path: PropTypes.string,
    onClick: PropTypes.func,
    isSelected: PropTypes.func
}

export default Comment;
