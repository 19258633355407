import LoadingButton from '@mui/lab/LoadingButton';
import { TextField } from '@mui/material';
import Stack from '@mui/material/Stack';
import PropTypes from 'prop-types';

const LoginForm = ({ onLogin, onEmailChange, onPasswordChange, loading }) => {
    return (
        <form onSubmit={onLogin}>
            <Stack spacing={4}>
                <TextField label="Email" type="email" required onChange={(e) => onEmailChange(e.target.value)} />
                <TextField label="Password" type="password" required onChange={(e) => onPasswordChange(e.target.value)} />
                <LoadingButton type="submit" variant="outlined" size="large" loading={loading}>Login</LoadingButton>
            </Stack>
        </form>
    );
}

LoginForm.propTypes = {
    onLogin: PropTypes.func,
    onEmailChange: PropTypes.func,
    onPasswordChange: PropTypes.func,
    loading: PropTypes.bool
};

export default LoginForm;