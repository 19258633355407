import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';

import consts from '../common/consts';
import { Container } from '@mui/material';

const Footer = () => {

    const { siteTitle, staticUrl } = consts.siteMetadata;

    return (
        <Container component="footer" sx={{ typography: 'subtitle1' }}>
            <Stack
                direction={{ md: 'row', xs: 'column' }}
                sx={{
                    boxSizing: 'border-box',
                    justifyContent: 'space-between',
                    mt: 16,
                    color: '#5f6c80',
                    a: {
                        color: '#5f6c80',
                        textDecoration: 'none',
                        ":hover": {
                            color: '#000',
                            textDecoration: 'underline',
                        },
                        ":focus": {
                            color: '#000',
                        },
                    },
                    borderTopStyle: 'solid',
                    borderTopWidth: '1px',
                    borderTopColor: '#cbd5e0',
                    pt: 3,
                }}
            >
                <Stack direction="row" gap={2}>
                    <Link href={`${staticUrl}/impressum`}>
                        Legal
                    </Link>
                    <Link href={`${staticUrl}/privacy-policy`}>
                        Privacy Policy
                    </Link>
                    <Link href={`${staticUrl}/tos`}>
                        Terms of Service
                    </Link>
                </Stack>
                <div>
                    &copy; {new Date().getFullYear()} by {siteTitle}. All rights reserved.
                </div>
            </Stack>
        </Container>
    );
}

export default Footer;