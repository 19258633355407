import { fetchJson } from "../common/fetcher";

const billingUrl = `${process.env.REACT_APP_API_URL}/billing`;

const billingService = {
    listProducts: async () => {
        try {
            const response = await fetchJson(`${billingUrl}/products`, {
                method: 'GET',
                credentials: 'include',
            });
            return response;
        } catch (error) {
            return { error: error.message };
        }
    },
    listEntitlements: async () => {
        try {
            const response = await fetchJson(`${billingUrl}/entitlements`, {
                method: 'GET',
                credentials: 'include',
            });
            return response;
        } catch (error) {
            return { error: error.message };
        }
    }
};

export default billingService;