import PropTypes from 'prop-types';
import { Button, TextField } from '@mui/material';

const PasswordResetRequestForm = ({ onRequestReset, onEmailChange }) => {
    return (
        <form onSubmit={onRequestReset}>
            <TextField label="Email" type="email" required onChange={(e) => onEmailChange(e.target.value)} />
            <Button type="submit">Request Password Reset</Button>
        </form>
    );
}

PasswordResetRequestForm.propTypes = {
    onRequestReset: PropTypes.func,
    onEmailChange: PropTypes.func
};

export default PasswordResetRequestForm;