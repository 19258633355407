import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types';

const ResumeUpload = ({ onUpload, onResumeTextChange }) => {
    return (
        <form onSubmit={onUpload}>
            <Stack spacing={4}>
                <TextField
                    id="resumeText"
                    multiline
                    rows={20}
                    onChange={(e) => onResumeTextChange(e.target.value)}
                />
                <Box>
                    <Button type="submit" variant="outlined" size="large">Upload</Button>
                </Box>
            </Stack>
        </form>
    );
}

ResumeUpload.propTypes = {
    onUpload: PropTypes.func,
    onResumeTextChange: PropTypes.func
};

export default ResumeUpload;