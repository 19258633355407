import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Image from 'mui-image';

import logo from '../../../logo.svg';
import consts from '../../../common/consts';

const Footer = () => {

    const { siteTitle, staticUrl } = consts.siteMetadata;

    return (
        <Box component="footer" sx={{ bgcolor: 'neutral.900', color: 'white' }}>
            <Container>
                <Stack direction="column" spacing={6} paddingBottom={10}>
                    <Box sx={{ display: 'flex', justifyContent: 'center', position: 'relative' }}>
                        <Box sx={{
                            position: 'absolute',
                            height: '1px',
                            bgcolor: 'white',
                            opacity: 0.1,
                            bottom: '50%',
                            left: 0,
                            right: 0
                        }} />
                        <Box sx={{ bgcolor: 'neutral.900', px: 6, position: 'relative' }}>
                            <Image src={logo} alt="Logomark" width={48} height={48} sx={{ objectPosition: '-2px top' }} />
                        </Box>
                    </Box>
                    <Stack
                        direction={{ lg: 'row', xs: 'column' }}
                        justifyContent="space-between"
                        alignItems="center"
                        textAlign={{ lg: 'left', xs: 'center' }}
                        spacing={4}
                        sx={{
                            a: { color: 'primary.light', textDecoration: 'inherit' }
                        }}
                    >
                        <Typography sx={{
                            typography: 'subtitle1',
                            fontWeight: 500,
                            color: 'neutral.400'
                        }}>
                            <Stack direction="row" gap={4}>
                                <Link href={`${staticUrl}/impressum`}>
                                    Legal
                                </Link>
                                <Link href={`${staticUrl}/privacy-policy`}>
                                    Privacy Policy
                                </Link>
                                <Link href={`${staticUrl}/tos`}>
                                    Terms of Service
                                </Link>
                            </Stack>
                        </Typography>
                        <Typography sx={{
                            typography: 'subtitle1',
                            fontWeight: 500,
                            color: 'neutral.400'
                        }}>
                            &copy; {new Date().getFullYear()} by {siteTitle}. All rights reserved.
                            Design by <Link href="https://landify.design/?ref=regim" rel="nofollow">
                                Landify
                            </Link>
                        </Typography>
                    </Stack>
                </Stack>
            </Container>
        </Box>
    );
}

export default Footer;
