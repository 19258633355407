import get from 'lodash/get';
import PropTypes from 'prop-types';

import styles from './ResumeView.module.css';
import { useEffect, useRef } from 'react';

const Field = (props) => {

    const { data, path, className, onClick, isSelected, children } = props;
    const ref = useRef(null);

    const handleClick = (e) => {
        onClick && onClick(e, path);
    }

    const comments = get(data, path ? `${path}.comments` : 'comments');
    const hasComments = Array.isArray(comments) && comments.length > 0;
    const isFieldSelected = isSelected && isSelected(path);

    const classes = [
        styles.field,
        className,
        hasComments ? styles.highlight : '',
        isFieldSelected ? styles.highlightSelected : ''
    ].filter(Boolean).join(' ');

    useEffect(() => {
        if (isFieldSelected) {
            ref.current.scrollIntoView({
                behavior: 'smooth',
                block: 'center'
            });
        }
    }, [isFieldSelected]);

    return (
        <div
            ref={ref}
            className={classes}
            onClick={handleClick}>
            {children ? children : get(data, `${path}.value`)}
        </div>
    );

}

Field.propTypes = {
    data: PropTypes.object,
    path: PropTypes.string,
    className: PropTypes.string,
    onClick: PropTypes.func,
    isSelected: PropTypes.func,
    children: PropTypes.node
}

export default Field;