import { ThemeProvider } from '@mui/material';
import Box from '@mui/material/Box';

import theme from './theme';

import FeedbackButton from '../../components/FeedbackButton';
import Navbar from '../../components/Navbar';
import Cta from "./components/cta";
import Features from './components/features';
import Footer from "./components/footer";
import Hero from './components/hero';
import Reviews from './components/reviews';

const IndexPage = () => {

    return <Box sx={{ overflowX: 'hidden ' }}>
        <ThemeProvider theme={theme}>
            <Navbar>
                <Hero />
                <Features />
                <Reviews />
                <Cta />
                <Footer />
                <FeedbackButton />
            </Navbar>
        </ThemeProvider>
    </Box>

}

export default IndexPage;
