import PropTypes from 'prop-types';
import { Button, TextField } from '@mui/material';

const PasswordResetForm = ({ onReset, onPasswordChange }) => {

    return (
        <form onSubmit={onReset}>
            <TextField label="New Password" type="password" required onChange={(e) => onPasswordChange(e.target.value)} />
            <Button type="submit">Reset Password</Button>
        </form>
    );
}

PasswordResetForm.propTypes = {
    onReset: PropTypes.func,
    onPasswordChange: PropTypes.func
};

export default PasswordResetForm;