import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    typography: {
        fontFamily: '"Source Sans 3", sans-serif',
        h1: {
            fontFamily: '"DM Sans", sans-serif'
        },
        h2: {
            fontFamily: '"DM Sans", sans-serif'
        },
        h3: {
            fontFamily: '"DM Sans", sans-serif'
        },
        h4: {
            fontFamily: '"DM Sans", sans-serif'
        },
        h5: {
            fontFamily: '"DM Sans", sans-serif'
        },
        h6: {
            fontFamily: '"DM Sans", sans-serif'
        },
        subtitle1: {
            fontFamily: '"DM Sans", sans-serif'
        },
        subtitle2: {
            fontFamily: '"DM Sans", sans-serif'
        },
        body1: {
            fontFamily: '"Source Sans 3", sans-serif'
        },
        body2: {
            fontFamily: '"Source Sans 3", sans-serif'
        },
        button: {
            fontFamily: '"DM Sans", sans-serif',
            textTransform: 'none',
            fontWeight: 'bold',
            fontSize: '1rem'
        },
        caption: {
            fontFamily: '"Source Sans 3", sans-serif'
        },
        overline: {
            fontFamily: '"Source Sans 3", sans-serif'
        }
    }
});

export default theme;