import { ThemeProvider } from "@mui/material";
import { Outlet } from "react-router-dom";

import "@fontsource/dm-sans/400.css";
import "@fontsource/dm-sans/700.css";
import "@fontsource/source-sans-3/400-italic.css";
import "@fontsource/source-sans-3/400.css";
import "@fontsource/source-sans-3/700-italic.css";
import "@fontsource/source-sans-3/700.css";

import FeedbackButton from "../components/FeedbackButton";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import "./Layout.css";
import theme from "./theme";

const Layout = () => {

    return (
        <ThemeProvider theme={theme}>
            <Navbar>
                <Outlet />
                <FeedbackButton />
                <Footer />
            </Navbar>
        </ThemeProvider>
    );

}

export default Layout;