import Button from '@mui/material/Button';
import PropTypes from 'prop-types';

import GoogleIcon from './GoogleIcon';

const GoogleSignInButton = ({ onGoogleSignIn }) => {

    return (
        <Button
            variant="outlined"
            size="large"
            onClick={onGoogleSignIn}
            startIcon={<GoogleIcon />}
            sx={{
                borderColor: '#dadce0',
                color: '#3c4043',
                letterSpacing: 'normal'
            }}
        >
            Sign in with Google
        </Button>
    );
};

GoogleSignInButton.propTypes = {
    onGoogleSignIn: PropTypes.func
}

export default GoogleSignInButton;
