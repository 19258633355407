import candidate from './candidate';
import certifications from './certifications';
import education from './education';
import jobs from './jobs';
import plain from './plain';
import projects from './projects';

export default {
    candidate,
    certifications,
    education,
    jobs,
    plain,
    projects
};