import PropTypes from 'prop-types';

import Comment from './Comment';

import styles from './ResumeView.module.css';

const CommentsSidebar = (props) => {

    const { data, isSelected, onClick } = props;

    return (
        <div className={`${styles.commentsSidebar} ${styles.commentsColumn}`}>
            <Comment data={data} path='candidate.name' isSelected={isSelected} onClick={onClick} />
            <Comment data={data} path='candidate.position' isSelected={isSelected} onClick={onClick} />
            <Comment data={data} path='candidate.phone' isSelected={isSelected} onClick={onClick} />
            <Comment data={data} path='candidate.email' isSelected={isSelected} onClick={onClick} />
            <Comment data={data} path='candidate.location' isSelected={isSelected} onClick={onClick} />
            <Comment data={data} path='candidate.industry' isSelected={isSelected} onClick={onClick} />
            <Comment data={data} path='candidate.salary' isSelected={isSelected} onClick={onClick} />
            {Array.isArray(data.jobs.value) && data.jobs.value.map((j, idx) =>
                <div key={idx} className={styles.commentsColumn}>
                    <Comment data={data} path={`jobs.value[${idx}]`} isSelected={isSelected} onClick={onClick} />
                    <Comment data={data} path={`jobs.value[${idx}].position`} isSelected={isSelected} onClick={onClick} />
                    <Comment data={data} path={`jobs.value[${idx}].company`} isSelected={isSelected} onClick={onClick} />
                    <Comment data={data} path={`jobs.value[${idx}].size`} isSelected={isSelected} onClick={onClick} />
                    <Comment data={data} path={`jobs.value[${idx}].profile`} isSelected={isSelected} onClick={onClick} />
                    <Comment data={data} path={`jobs.value[${idx}].startDate`} isSelected={isSelected} onClick={onClick} />
                    <Comment data={data} path={`jobs.value[${idx}].endDate`} isSelected={isSelected} onClick={onClick} />
                    <Comment data={data} path={`jobs.value[${idx}].description`} isSelected={isSelected} onClick={onClick} />
                    {j.description.value.map((_, dIdx) => (
                        <Comment key={dIdx} data={data} path={`jobs.value[${idx}].description.value[${dIdx}]`} isSelected={isSelected} onClick={onClick} />
                    ))}
                </div>
            )}
            {Array.isArray(data.schools.value) && data.schools.value.map((_, idx) =>
                <div key={idx} className={styles.commentsColumn}>
                    <Comment data={data} path={`schools.value[${idx}]`} isSelected={isSelected} onClick={onClick} />
                    <Comment data={data} path={`schools.value[${idx}].name`} isSelected={isSelected} onClick={onClick} />
                    <Comment data={data} path={`schools.value[${idx}].degreeType`} isSelected={isSelected} onClick={onClick} />
                    <Comment data={data} path={`schools.value[${idx}].major`} isSelected={isSelected} onClick={onClick} />
                    <Comment data={data} path={`schools.value[${idx}].minor`} isSelected={isSelected} onClick={onClick} />
                    <Comment data={data} path={`schools.value[${idx}].graduationDate`} isSelected={isSelected} onClick={onClick} />
                    <Comment data={data} path={`schools.value[${idx}].achievements`} isSelected={isSelected} onClick={onClick} />
                </div>
            )}
            {Array.isArray(data.certifications.value) && data.certifications.value.map((_, idx) =>
                <div key={idx} className={styles.commentsColumn}>
                    <Comment data={data} path={`certifications.value[${idx}]`} isSelected={isSelected} onClick={onClick} />
                    <Comment data={data} path={`certifications.value[${idx}].title`} isSelected={isSelected} onClick={onClick} />
                    <Comment data={data} path={`certifications.value[${idx}].completionDate`} isSelected={isSelected} onClick={onClick} />
                    <Comment data={data} path={`certifications.value[${idx}].description`} isSelected={isSelected} onClick={onClick} />
                </div>
            )}
        </div>
    );

}

CommentsSidebar.propTypes = {
    data: PropTypes.object,
    isSelected: PropTypes.func,
    onClick: PropTypes.func
}

export default CommentsSidebar;