import consts from './consts';

export const fetchJson = async (url, options = {}) => {

    let response, json;

    const controller = new AbortController();
    const timeout = setTimeout(() => controller.abort(), consts.api.timeout)

    try {
        response = await fetch(url, { ...options, signal: controller.signal });
    } catch (error) {
        if (error.message === 'Network request failed' ||
            error.name === 'AbortError') {
            throw new Error('Network error');
        }
        throw error;
    } finally {
        clearTimeout(timeout);
    }

    if (!response.ok) {
        throw new Error(`${response.status} ${response.statusText}`);
    }

    try {
        json = await response.json();
    } catch (error) {
        throw new Error('Invalid JSON');
    }

    if (json.error) {
        throw new Error(json.error.message);
    }

    return json;
}
