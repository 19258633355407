import { createContext, useContext, useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';

import authService from './services/authService';

const AuthContext = createContext();

export const AuthProvider = () => {

    const [user, setUser] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    const handleLogin = async (credentials) => {

        setIsLoading(true);
        const response = await authService.login(credentials);
        setIsLoading(false);

        if (response.error) {
            console.log(response.error);
        } else {
            setUser(response.user);
        }

        return { error: response.error };

    };

    const handleRegister = async (credentials) => {

        setIsLoading(true);
        const response = await authService.register(credentials);
        setIsLoading(false);

        if (response.error) {
            console.log(response.error);
        } else {
            setUser(response.user);
        }

        return { error: response.error };

    };

    const handleGoogleSignIn = async (returnUrl) => {

        setIsLoading(true);
        const response = await authService.signInWithGoogle(returnUrl);
        setIsLoading(false);

        if (response.error) {
            console.log(response.error);
        } else {
            window.location.replace(response.authUrl);
        }

        return { error: response.error };

    };

    const handleLogout = async () => {

        setIsLoading(true);
        const response = await authService.logout();
        setIsLoading(false);

        if (response.error) {
            console.log(response.error);
        } else {
            setUser(null);
        }

        return { error: response.error };

    };

    const value = {
        user: user,
        setUser: setUser,
        isLoading: isLoading,
        onLogin: handleLogin,
        onRegister: handleRegister,
        onGoogleSignIn: handleGoogleSignIn,
        onLogout: handleLogout
    };

    useEffect(() => {

        const getCurrentUser = async () => {

            setIsLoading(true);
            const response = await authService.current();
            setIsLoading(false);

            if (response.error) {
                setUser(null);
            } else {
                setUser(response.user);
            }

        }

        getCurrentUser();

    }, []);

    console.log(`AuthProvider rendering, current user: ${JSON.stringify(user)}`);

    return (
        <AuthContext.Provider value={value}>
            <Outlet />
        </AuthContext.Provider>
    );

};

export const useAuth = () => {
    return useContext(AuthContext);
};
