import { fetchJson } from "../common/fetcher";

const authService = {
    current: async () => {
        try {
            const response = await fetchJson(`${process.env.REACT_APP_API_URL}/auth/current`, {
                method: 'GET',
                credentials: 'include',
            });
            return response;
        } catch (error) {
            return { error: error.message };
        }
    },
    login: async (credentials) => {
        try {
            const response = await fetchJson(`${process.env.REACT_APP_API_URL}/auth/login`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(credentials),
                credentials: 'include',
            });
            return response;
        } catch (error) {
            return { error: error.message };
        }
    },
    register: async (credentials) => {
        try {
            const response = await fetchJson(`${process.env.REACT_APP_API_URL}/auth/register`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(credentials),
                credentials: 'include',
            });
            return response;
        } catch (error) {
            return { error: error.message };
        }
    },
    logout: async () => {
        try {
            const response = await fetchJson(`${process.env.REACT_APP_API_URL}/auth/logout`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                credentials: 'include',
            });
            return response;
        } catch (error) {
            return { error: error.message };
        }
    },
    signInWithGoogle: async (returnUrl) => {
        try {
            const response = await fetchJson(`${process.env.REACT_APP_API_URL}/auth/google`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ returnUrl }),
                credentials: 'include',
            });
            return response;
        } catch (error) {
            return { error: error.message };
        }
    },
    confirmEmail: async (token) => {
        try {
            const response = await fetchJson(`${process.env.REACT_APP_API_URL}/auth/confirm`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ token }),
                credentials: 'include',
            });
            return response;
        } catch (error) {
            return { error: error.message };
        }
    },
    resetPassword: async (email, token, newPassword) => {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/auth/reset-password/confirm`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ email, token, newPassword }),
        });
        return response.ok;
    },
};

export default authService;