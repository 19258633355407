import Container from '@mui/material/Container';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useState } from 'react';
import { Link, Navigate, useLocation } from 'react-router-dom';

import { useAuth } from '../auth';
import GoogleSignInButton from '../components/GoogleSignInButton';
import LoginForm from '../components/LoginForm';

const LoginPage = () => {

    const location = useLocation();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null);
    const origin = location.state?.from?.pathname || '/';

    const auth = useAuth();

    const handleSubmit = async (e) => {
        e.preventDefault();

        setError(null);
        const result = await auth.onLogin({ email, password });
        if (result.error) {
            setError(result.error);
        }
    };

    const handleGoogleSignIn = async () => {
        const fullOrigin = window.location.origin + origin;

        setError(null);
        const result = await auth.onGoogleSignIn(fullOrigin);
        if (result.error) {
            setError(result.error);
        }
    }

    if (auth.user) {
        return <Navigate to={origin} replace />;
    }

    return (
        <Container sx={{ width: 400, maxWidth: '100%' }}>
            <Typography variant="h4" sx={{ textAlign: 'center', pb: 8 }}>Welcome back to your engineering resume</Typography>
            <Stack spacing={6}>
                <GoogleSignInButton onGoogleSignIn={handleGoogleSignIn} />
                <Divider>or log in with e-mail</Divider>
                <LoginForm
                    onLogin={handleSubmit}
                    onEmailChange={setEmail}
                    onPasswordChange={setPassword}
                    loading={auth.isLoading} />
                {error && <Alert severity="error">{error}</Alert>}
                <Divider>Don&apos;t have an account?</Divider>
                <Button component={Link} to="/register">Sign up</Button>
            </Stack>
        </Container>
    );
}

export default LoginPage;