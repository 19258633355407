import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import Button from "./button";

const Cta = () => (
    <Box sx={{ bgcolor: 'neutral.900', color: 'white', py: 16 }}>
        <Container>
            <Box sx={{
                display: 'grid',
                gridTemplateColumns: { xl: 'repeat(12, minmax(0, 1fr))', xs: 'minmax(0, 1fr)' },
                alignItems: 'center',
                textAlign: 'center',
                py: { xl: 12 },
                gap: 10
            }}>
                <Box sx={{
                    display: { xl: 'block', xs: 'none' },
                    gridColumn: 'span 2 / span 2'
                }} />
                <Stack direction="column" alignItems="center" spacing={10}
                    sx={{
                        gridColumn: { xl: 'span 8 / span 8' }
                    }}
                >
                    <Stack direction="column" spacing={4}>
                        <Typography component="h3"
                            sx={{
                                typography: { lg: 'h3', md: 'h4', xs: 'h5' },
                                fontWeight: 600
                            }}
                        >
                            Get more job interviews now
                        </Typography>
                        <Typography sx={{ typography: { md: 'body-lg', xs: 'body-md' } }}>
                            Land your dream job, get paid better, push your career forward
                        </Typography>
                    </Stack>
                    <Button link="/register" label="Get Instant Access" size="lg" />
                </Stack>
                <Box sx={{
                    display: { xl: 'block', xs: 'none' },
                    gridColumn: 'span 2 / span 2'
                }} />
            </Box>
        </Container>
    </Box>
);

export default Cta;