import Container from '@mui/material/Container';

const NotFoundPage = () => {
    return (
        <Container>
            <h1>404</h1>
            <p>Sorry, there&apos;s nothing here.</p>
        </Container>
    );
}
export default NotFoundPage;