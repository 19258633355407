import Container from '@mui/material/Container';
import { useEffect, useState } from 'react';
import { Navigate, useParams } from 'react-router-dom';

import ResumeView from '../components/ResumeView/ResumeView';
import resumeService from "../services/resumeService";

const ResumePage = () => {

    let { resumeId } = useParams();

    const [resume, setResume] = useState();
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {

        const loadResume = async () => {
            setIsLoading(true);
            const result = await resumeService.get(resumeId);
            setResume(result.resume);
            setIsLoading(false);
        }

        loadResume();

    }, [resumeId])

    if (!isLoading && (!resume || resume.status !== 'ready')) {
        return <Navigate to="/" />
    }

    return (
        <Container>
            {!isLoading && <ResumeView resume={resume} />}
        </Container>
    )
}

export default ResumePage;