import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';

import ResumeUpload from './ResumeUpload';

const ResumeUploadModal = ({ open, onClose, onUpload, onResumeTextChange }) => {

    return (
        <Modal
            open={open}
            onClose={onClose}
        >
            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: ['95%', 'md'],
                maxWidth: ['95%', 'md'],
                bgcolor: 'white',
                boxShadow: 24,
                p: 6,
                outline: 'none'
            }}>
                <Typography variant="h6" gutterBottom>
                    Copy and paste your resume text here
                </Typography>
                <Typography variant="subtitle1" sx={{ mb: 4 }}>
                    Don&apos;t worry much about formatting, we&apos;ll take care of that
                </Typography>
                <ResumeUpload
                    onUpload={onUpload}
                    onResumeTextChange={onResumeTextChange}
                />
            </Box>
        </Modal>
    );

}

ResumeUploadModal.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    onUpload: PropTypes.func,
    onResumeTextChange: PropTypes.func
};


export default ResumeUploadModal;