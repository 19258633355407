import Container from '@mui/material/Container';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import authService from '../services/authService';

const EmailConfirmationPage = () => {

    let { emailConfirmationToken } = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {

        const loadResume = async () => {

            if (!emailConfirmationToken) {
                return;
            }

            setIsLoading(true);
            const result = await authService.confirmEmail(emailConfirmationToken);
            console.log(JSON.stringify(result));
            if (result.error) {
                console.log(`setting error ${result.error}`)
                setError(result.error);
            }
            setIsLoading(false);
        }

        loadResume();

    }, [])

    let message = <>
        <h1>Thank you, your e-mail is now confirmed!</h1>
        <div>You can now use it to log in to your account</div>
    </>;

    if (!emailConfirmationToken) {
        message = <h1>Please check your e-mail for a confirmation link.</h1>;
    } else if (isLoading) {
        message = <>Confirming e-mail, please wait...</>;
    } else if (error) {
        message = <>Your e-mail confirmation link is invalid. If your e-mail is not confirmed, request a new link.</>
    }

    return (
        <Container>
            {message}
        </Container>
    )

}

export default EmailConfirmationPage;
