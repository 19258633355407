import Field from '../Field';
import styles from '../ResumeView.module.css';
import { Fragment } from 'react';

const jobs = (params) => {

    const { data, isSelected, onClick, position } = params;

    const elements = [];

    if (position !== 'top') {
        elements.push(
            <div key='jobs-title' className={styles.sectionTitle}>Experience</div>,
            <div key='jobs-title-gap' className={styles.gap16}></div>,
        )
    }

    elements.push(...data.jobs.value.map((j, idx, arr) => (
        <Fragment key={`job-${idx}`}>
            <div className={styles.jobCompanyProfile}>
                <Field className={styles.jobCompany} data={data} path={`jobs.value[${idx}].company`} isSelected={isSelected} onClick={onClick} />
                <div className={styles.jobProfile}>
                    <Field data={data} path={`jobs.value[${idx}].profile`} isSelected={isSelected} onClick={onClick} />
                    <Field data={data} path={`jobs.value[${idx}].size`} isSelected={isSelected} onClick={onClick} />
                </div>
            </div>
            <div className={styles.gap16}></div>
            <div className={styles.jobPositionDates}>
                <Field className={styles.jobPosition} data={data} path={`jobs.value[${idx}].position`} isSelected={isSelected} onClick={onClick} />
                <div className={styles.jobDates}>
                    <Field data={data} path={`jobs.value[${idx}].startDate`} isSelected={isSelected} onClick={onClick} /> —
                    <Field data={data} path={`jobs.value[${idx}].endDate`} isSelected={isSelected} onClick={onClick} />
                </div>
            </div>
            <div className={styles.gap16}></div>
            <Field className={styles.jobDescription} data={data} path={`jobs.value[${idx}].description`} isSelected={isSelected} onClick={onClick}>
                <ul>
                    {j.description.value.map((d, dIdx) => {
                        if (!d.value || d.value.replace(/^\s+|\s+$/g, '').length === 0) {
                            return null;
                        }
                        return (
                            <li key={dIdx}>
                                <Field className={styles.inline} data={data} path={`jobs.value[${idx}].description.value[${dIdx}]`} isSelected={isSelected} onClick={onClick} />
                            </li>
                        )
                    })}
                </ul>
            </Field>

            {(idx < arr.length - 1 || position !== 'bottom') &&
                <div className={styles.gap32}></div>
            }
        </Fragment>
    )))

    return elements;
}

export default jobs;