const consts = {
    api: {
        timeout: 10000
    },
    siteMetadata: {
        siteTitle: 'SWE Resume',
        staticUrl: process.env.REACT_APP_STATIC_WEBSITE_URL
    },
};

export default consts;