import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import FeatureItem from "./featureItem";

import DetailedCommentsIcon from '../images/feature-icons/detailed-comments.png';
import InstantResultIcon from '../images/feature-icons/instant-result.png';
import LotsOfIdeasIcon from '../images/feature-icons/lots-of-ideas.png';
import PerfectTemplateIcon from '../images/feature-icons/perfect-template.png';
import SimpleImportIcon from '../images/feature-icons/simple-import.png';

import Button from "./button";

const Features = () => {

    const features = [
        {
            "title": "Simple Import",
            "description": "Copy and paste your resume text or upload a PDF file.",
            "icon": SimpleImportIcon
        },
        {
            "title": "Instant Result",
            "description": "Get your resume reviewed in a couple of minutes.",
            "icon": InstantResultIcon
        },
        {
            "title": "Lots of Ideas",
            "description": "Detailed feedback on your resume and a list of specific ideas for improvement.",
            "icon": LotsOfIdeasIcon
        },
        {
            "title": "Detailed Comments",
            "description": "Detailed comments with examples on individual wording in your resume.",
            "icon": DetailedCommentsIcon
        },
        {
            "title": "Perfect Template",
            "description": "Resume template tailored for IT specialists and engineers.",
            "icon": PerfectTemplateIcon
        }
    ];

    return (
        <Container sx={{ px: { sm: '24px', xs: '16px' } }}>
            <Stack direction="column" alignItems="center" spacing={12}
                sx={{
                    py: { md: 24, xs: 12 }
                }}
            >
                <Box sx={{
                    display: 'grid',
                    gridTemplateColumns: { xl: 'repeat(12, minmax(0, 1fr))', xs: 'minmax(0, 1fr)' },
                    gap: 8,
                    textAlign: 'center',
                    pb: { md: 24, xs: 12 },
                    borderBottom: 1,
                    borderColor: 'neutral.200'
                }}>
                    <Box sx={{
                        gridColumn: { xl: 'span 2 / span 2' },
                        display: { xl: 'block', xs: 'none' }
                    }} />
                    <Stack direction="column" spacing={12} justifyContent="center"
                        sx={{
                            gridColumn: { xl: 'span 8 / span 8' },

                        }}>
                        <Stack direction="column" spacing={2} textAlign="center">
                            <Typography component="h2"
                                sx={{
                                    typography: { md: 'h4', xs: 'h5' },
                                    fontWeight: 600
                                }}
                            >
                                Do you make these typical mistakes in&nbsp;your&nbsp;resume?
                            </Typography>
                            <Typography
                                sx={{
                                    typography: { md: 'body-lg', xs: 'body-md' },
                                    color: 'neutral.700'
                                }}
                            >
                                <List>
                                    <ListItem>
                                        <ListItemIcon>
                                            <CloseIcon color="error" fontSize="large" />
                                        </ListItemIcon>
                                        <ListItemText disableTypography primary="Making a list of what you did and what you were responsible for" />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemIcon>
                                            <CloseIcon color="error" fontSize="large" />
                                        </ListItemIcon>
                                        <ListItemText disableTypography primary="Trying to squeeze in every skill you have" />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemIcon>
                                            <CloseIcon color="error" fontSize="large" />
                                        </ListItemIcon>
                                        <ListItemText disableTypography primary="Spend too much time on choosing beautiful template" />
                                    </ListItem>
                                    <ListItem>
                                        <Box>Result: <strong>not getting interviews</strong></Box>
                                    </ListItem>
                                </List>
                                <Box sx={{ textAlign: 'left ' }}>

                                </Box>
                            </Typography>
                        </Stack>
                        <Stack direction="column" spacing={2} textAlign="center">
                            <Typography component="h2"
                                sx={{
                                    typography: { md: 'h4', xs: 'h5' },
                                    fontWeight: 600
                                }}
                            >
                                What to do instead
                            </Typography>
                            <Typography
                                sx={{
                                    typography: { md: 'body-lg', xs: 'body-md' },
                                    color: 'neutral.700'
                                }}
                            >
                                <List>
                                    <ListItem>
                                        <ListItemIcon>
                                            <CheckIcon color="success" fontSize="large" />
                                        </ListItemIcon>
                                        <ListItemText disableTypography primary="Tell what you have personally accomplished and how it has benefited the business" />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemIcon>
                                            <CheckIcon color="success" fontSize="large" />
                                        </ListItemIcon>
                                        <ListItemText disableTypography primary="Focus on the most relevant and recent experience, save the rest for the interview" />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemIcon>
                                            <CheckIcon color="success" fontSize="large" />
                                        </ListItemIcon>
                                        <ListItemText disableTypography primary="Use a clean and simple template so your resume is easy to scan and find information" />
                                    </ListItem>
                                    <ListItem>
                                        This is what recruiters and hiring managers look for.
                                    </ListItem>
                                </List>
                            </Typography>
                        </Stack>
                    </Stack>
                    <Box sx={{
                        gridColumn: { xl: 'span 2 / span 2' },
                        display: { xl: 'block', xs: 'none' }
                    }} />
                </Box>
                <Box sx={{
                    textAlign: 'center'
                }}>
                    <Typography component="h2" id="features"
                        sx={{
                            pt: { md: 12, xs: 4 },
                            typography: { md: 'h4', xs: 'h5' },
                            fontWeight: 600
                        }}
                    >
                        Here&apos;s how it works
                    </Typography>
                </Box>
                <Stack direction="row" justifyContent="center" flexWrap="wrap" sx={{
                    gap: { sm: 8, xs: 0 },
                    pt: { md: 10, xs: 0 },
                    pb: { md: 6, xs: 0 }
                }}>
                    {features.map((feature, idx) => (
                        <FeatureItem
                            key={idx}
                            icon={feature.icon}
                            title={feature.title}
                            description={feature.description}
                        />
                    ))}
                </Stack>
                <Button link="/register" label="Get Instant Access" size="lg" />
            </Stack>
        </Container>
    );

};
export default Features;
