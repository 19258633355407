import CheckIcon from '@mui/icons-material/Check';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useEffect, useState } from 'react';

import { useAuth } from '../auth';
import InputFileUpload from '../components/InputFileUpload';
import Paywall from '../components/Paywall';
import ResumeList from '../components/ResumeList';
import ResumeUploadModal from '../components/ResumeUploadModal';
import resumeService from "../services/resumeService";

const HomePage = () => {

    const auth = useAuth();

    const [resumeList, setResumeList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [retryCount, setRetryCount] = useState(0);

    const [resumeText, setResumeText] = useState('');
    const [uploadModalOpen, setUploadModalOpen] = useState(false);
    const [paywallOpen, setPaywallOpen] = useState(false);

    const [isFileUploading, setIsFileUploading] = useState(false);
    const [uploadSuccessfulAlert, setUploadSuccessfulAlert] = useState(false);

    const getResumeList = async () => {
        setIsLoading(true);
        const result = await resumeService.list();
        setResumeList(result.resumes);
        setRetryCount(c => c + 1);
        setIsLoading(false);
    }

    useEffect(() => {

        getResumeList();

    }, []);

    useEffect(() => {

        if (isLoading) {
            return;
        }

        if (resumeList.length === 0 && auth.user.entitlements.length === 0) {
            setPaywallOpen(true);
        }

        const hasResumeInProcess = resumeList.some(resume => resume.status === 'processing');
        if (hasResumeInProcess) {
            // exponential decay after 10 attempts and still in process
            const delay = retryCount < 10 ? 5000 : 5000 * Math.pow(2, retryCount - 9);
            const timer = setTimeout(getResumeList, delay);
            return () => clearTimeout(timer);
        } else {
            setUploadSuccessfulAlert(false);
        }

        setRetryCount(0);

    }, [resumeList])

    const handleUpload = async (e) => {

        e.preventDefault();

        if (resumeText.length === 0) {
            return;
        }

        setUploadModalOpen(false);
        const result = await resumeService.upload(resumeText);
        // TODO: error handling
        auth.setUser(user => (
            {
                ...user,
                ...result.user
            }
        ))
        setResumeList(list => [result.resume, ...list]);
        setRetryCount(0);
        setUploadSuccessfulAlert(true);

    };

    const handleFileUploadClick = async (e) => {
        if (auth.user.entitlements.length === 0) {
            e.preventDefault();
            setPaywallOpen(true);
        }
    }

    const handleFileUpload = async (e) => {

        const input = e.target;
        const file = input.files[0];

        if (!file) {
            return;
        }

        const formData = new FormData();
        formData.append(input.name, file);

        setIsFileUploading(true);

        const result = await resumeService.fileUpload(formData);
        // TODO: error handling
        auth.setUser(user => (
            {
                ...user,
                ...result.user
            }
        ))
        setResumeList(list => [result.resume, ...list]);
        setRetryCount(0);
        setIsFileUploading(false);
        setUploadSuccessfulAlert(true);

    };

    const handleAddResume = async (e) => {
        e.preventDefault();

        if (auth.user.entitlements.length > 0) {
            setUploadModalOpen(true);
        } else {
            setPaywallOpen(true);
        }
    }

    const handleUploadModalClose = () => {
        setUploadModalOpen(false);
    }

    const handlePaywallClose = () => {
        if (resumeList.length > 0) {
            setPaywallOpen(false);
        }
    }

    const handleDeleteResume = async (e, resumeId) => {
        e.preventDefault();
        const result = await resumeService.delete(resumeId);
        if (result.error) {
            // TODO: err handling
        }
        setResumeList(list => list.filter(i => i.resumeId !== resumeId));
    }

    if (resumeList.length === 0 && isLoading) {
        return;
    }

    return (
        <>
            <Paywall
                open={paywallOpen}
                onClose={handlePaywallClose}
            />
            <ResumeUploadModal
                open={uploadModalOpen}
                onClose={handleUploadModalClose}
                onResumeTextChange={setResumeText}
                onUpload={handleUpload}
            />
            {(resumeList.length === 0) ?
                <Container sx={{ width: 400, maxWidth: '100%' }}>
                    <Typography variant="h6" sx={{ mb: 6 }}>
                        Time to upload your first resume!
                    </Typography>
                    <Stack spacing={3}>
                        <InputFileUpload
                            name="resume"
                            title="Upload resume in PDF"
                            loading={isFileUploading}
                            accept=".pdf"
                            onClick={handleFileUploadClick}
                            onChange={handleFileUpload}
                        />
                        <Divider>or</Divider>
                        <Button variant="outlined" onClick={handleAddResume}>Copy and paste resume text</Button>
                    </Stack>
                </Container>
                :
                <Container>
                    {uploadSuccessfulAlert &&
                        <Alert
                            onClose={() => { setUploadSuccessfulAlert(false) }}
                            icon={<CheckIcon fontSize="inherit" />}
                            severity="success"
                            sx={{ mb: 5 }}
                        >
                            Your resume was successfully uploaded and is being processed. We will send you an email once completed.
                        </Alert>
                    }
                    <ResumeList
                        items={resumeList}
                        onUploadResumeClick={handleFileUploadClick}
                        onUploadResume={handleFileUpload}
                        isFileUploading={isFileUploading}
                        onCopyPasteResume={handleAddResume}
                        onDeleteResume={handleDeleteResume}
                    />

                </Container>
            }
        </>
    );
}

export default HomePage;