import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Image from 'mui-image';

const Reviews = () => {

    const theme = useTheme();

    const reviews = [
        {
            "name": "01-slack",
            "publicURL": "/images/integration-logos/01-slack.svg"
        },
        {
            "name": "02-quickbooks",
            "publicURL": "/static/5a030981a8a2fbab4c60205dd11f0b31/02-quickbooks.svg"
        },
        {
            "name": "03-mailchimp",
            "publicURL": "/static/4578b2ffc844691fd8b363a8dde7918a/03-mailchimp.svg"
        },
        {
            "name": "04-hubspot",
            "publicURL": "/static/e6601690546ed48d91272105c1c8c6d4/04-hubspot.svg"
        },
        {
            "name": "05-zapier",
            "publicURL": "/static/5ef720dd7b0ac02f8bf1ebeb975033b2/05-zapier.svg"
        },
        {
            "name": "06-google-analytics",
            "publicURL": "/static/dc5d180d4388418fb6a01efb9ad47557/06-google-analytics.svg"
        },
        {
            "name": "07-marketo",
            "publicURL": "./static/aedbd0209aa64e83600b99227a5b605f/07-marketo.svg"
        }
    ];

    return (
        <Container sx={{ px: { sm: '24px', xs: '16px' }, pb: { xl: 40, xs: 12 } }}>
            <Box
                sx={{
                    display: 'grid',
                    gridTemplateColumns: { xl: 'repeat(2, minmax(0, 1fr))', xs: 'minmax(0, 1fr)' },
                    gap: { xl: 8, xs: 10 },
                    py: { xl: 18, md: 12 },
                    pt: 12,
                    pb: 6,
                    px: { lg: 0, md: 12, xs: 0 },
                    alignItems: 'center',
                    borderTop: '1px solid',
                    borderColor: 'neutral.200',
                    position: 'relative',
                }}
            >
                <Box sx={{
                    position: 'absolute',
                    display: { xl: 'block', xs: 'flex' },
                    justifyContent: 'center',
                    left: { xl: 8 },
                    insetX: 0,
                    top: 0
                }}>
                    <Box
                        component="svg"
                        width="760"
                        height="380"
                        viewBox="0 0 760 380"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        sx={{
                            width: { md: '624px', xs: '304px' },
                            height: { md: '312px', xs: '152px' },
                        }}
                    >
                        <g clipPath="url(#clip0_2554_770)">
                            <circle
                                cx="380"
                                r="380"
                                fill={theme.palette.secondary.light}
                            />
                        </g>
                        <defs>
                            <clipPath id="clip0_2554_770">
                                <rect width="760" height="380" fill="white" />
                            </clipPath>
                        </defs>
                    </Box>
                </Box>
                <Stack direction="column" spacing={4}
                    sx={{
                        position: 'relative',
                        textAlign: { xl: 'left', xs: 'center' }
                    }}
                >
                    <Typography
                        component="h3"
                        sx={{
                            typography: { lg: 'h2', md: 'h3', xs: 'h4' },
                            fontWeight: 600
                        }}
                    >
                        Check out our customers reviews
                    </Typography>
                    <Typography sx={{
                        typography: { md: 'body-lg', xs: 'body-md' },
                        color: 'neutral.700'
                    }}>
                        Join our happy customers. If you are not satisfied with the results, we will give you your money back &mdash; no questions asked.
                    </Typography>
                </Stack>
                <Stack direction="row"
                    sx={{
                        flexWrap: 'wrap',
                        gap: { xl: 18, lg: 12, md: 18, xs: 8 },
                        alignItems: 'center',
                        justifyContent: 'center',
                        justifyItems: 'center',
                        position: 'relative'
                    }}
                >
                    {reviews.map((review, idx) => (
                        <Image
                            src={review.publicURL}
                            alt={review.name}
                            key={idx}
                            width={80}
                            height={80}
                            sx={{
                                boxShadow: 'xl',
                                borderRadius: '9999px',
                                width: { md: '80px', xs: '56px' },
                                height: { md: '80px', xs: '56px' }
                            }}
                        />
                    ))}
                </Stack>
            </Box>
        </Container>
    );
};
export default Reviews;
