import { Fragment } from 'react';

import Field from '../Field';
import styles from '../ResumeView.module.css';

const certifications = (params) => {

    const { data, isSelected, onClick, position } = params;

    if (data.certifications.value.length === 0) {
        return [];
    }

    return [
        <div key='certifications-title' className={styles.sectionTitle}>Courses and Certifications</div>,
        <div key='certifications-title-gap' className={styles.gap16}></div>,
        ...data.certifications.value.map((_, idx, arr) => (
            <Fragment key={`certifications-${idx}`}>
                <div className={styles.certificationNameDate}>
                    <Field className={styles.certificationName} data={data} path={`certifications.value[${idx}].title`} isSelected={isSelected} onClick={onClick} />
                    <Field className={styles.certificationDate} data={data} path={`certifications.value[${idx}].completionDate`} isSelected={isSelected} onClick={onClick} />
                </div>
                <div className={styles.gap16}></div>
                <Field className={styles.certificationDescription} data={data} path={`certifications.value[${idx}].description`} isSelected={isSelected} onClick={onClick} />
                {(idx < arr.length - 1 || position !== 'bottom') &&
                    <div className={styles.gap32}></div>
                }
            </Fragment>
        ))
    ];

}

export default certifications;