import { Fragment } from 'react';

import Field from '../Field';
import styles from '../ResumeView.module.css';

const projects = (params) => {

    const { data, isSelected, onClick, position } = params;

    if (data.projects.value.length === 0) {
        return [];
    }

    return [
        <div key='projects-title' className={styles.sectionTitle}>Personal Projects</div>,
        <div key='projects-title-gap' className={styles.gap16}></div>,
        ...data.projects.value.map((p, idx, arr) => (
            <Fragment key={`projects-${idx}`}>
                {p.organization.value &&
                    <>
                        <Field data={data} path={`projects.value[${idx}].organization`} isSelected={isSelected} onClick={onClick} />
                        <div className={styles.gap16}></div>
                    </>
                }
                <div className={styles.projectTitleDate}>
                    <Field className={p.organization.value ? '' : styles.projectTitle} data={data} path={`projects.value[${idx}].title`} isSelected={isSelected} onClick={onClick} />
                    <Field className={styles.projectDate} data={data} path={`projects.value[${idx}].completionDate`} isSelected={isSelected} onClick={onClick} />
                </div>
                <div className={styles.gap16}></div>
                <div className={styles.projectDescription}>
                    <Field data={data} path={`projects.value[${idx}].description`} isSelected={isSelected} onClick={onClick} />
                </div>
                {(idx < arr.length - 1 || position !== 'bottom') &&
                    <div className={styles.gap32}></div>
                }
            </Fragment>
        ))
    ];

}

export default projects;