import { Fragment } from 'react';

import Field from '../Field';
import styles from '../ResumeView.module.css';

const education = (params) => {

    const { data, isSelected, onClick, position } = params;

    if (data.schools.value.length === 0) {
        return [];
    }

    return [
        <div key='education-title' className={styles.sectionTitle}>Education</div>,
        <div key='education-title-gap' className={styles.gap16}></div>,
        ...data.schools.value.map((s, idx, arr) => (
            <Fragment key={`education-${idx}`}>
                {s.degree?.value ?
                    <>
                        <Field className={styles.schoolName} data={data} path={`schools.value[${idx}].name`} isSelected={isSelected} onClick={onClick} />
                        <div className={styles.gap16}></div>
                        <div className={styles.schoolDegreeDate}>
                            <div className={styles.schoolDegree}>
                                <div>
                                    <Field data={data} path={`schools.value[${idx}].degreeType`} isSelected={isSelected} onClick={onClick} />
                                    {s.major?.value ? ` in ` : ``}
                                    <Field data={data} path={`schools.value[${idx}].major`} isSelected={isSelected} onClick={onClick} />
                                    {s.minor?.value ? `, Minor in ${s.minor}` : ``}
                                    <Field data={data} path={`schools.value[${idx}].minor`} isSelected={isSelected} onClick={onClick} />
                                </div>
                                <Field className={styles.schoolDate} data={data} path={`schools.value[${idx}].graduationDate`} isSelected={isSelected} onClick={onClick} />
                            </div>
                        </div>
                    </>
                    :
                    <div className={styles.schoolNameDate}>
                        <Field className={styles.schoolName} data={data} path={`schools.value[${idx}].name`} isSelected={isSelected} onClick={onClick} />
                        <Field className={styles.schoolDate} data={data} path={`schools.value[${idx}].graduationDate`} isSelected={isSelected} onClick={onClick} />
                    </div>
                }

                {Array.isArray(s.achievements?.value) && s.achievements.length > 0 &&
                    <>
                        <div className={styles.gap16}></div>
                        <div className={styles.schoolDescription}>
                            <Field data={data} path={`schools.value[${idx}].achievements`} isSelected={isSelected} onClick={onClick} />
                        </div>
                    </>
                }
                
                {(idx < arr.length - 1 || position !== 'bottom') &&
                    <div className={styles.gap32}></div>
                }
            </Fragment>
        ))
    ];

}

export default education;