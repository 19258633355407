import get from 'lodash/get';
import PropTypes from 'prop-types';
import { useState } from 'react';

import "@fontsource/merriweather/400-italic.css";
import "@fontsource/merriweather/400.css";
import "@fontsource/merriweather/700-italic.css";
import "@fontsource/merriweather/700.css";

import sections from './sections';
import CommentsHeader from './CommentsHeader';
import CommentsSidebar from './CommentsSidebar';

import Pager from './Pager';
import styles from './ResumeView.module.css';
import { Typography } from '@mui/material';

const ResumeView = ({ resume }) => {

    const [selectedComment, setSelectedComment] = useState({});

    const handleClick = (e, path, idx = 0) => {
        const comments = get(resume, path ? `${path}.comments` : 'comments');
        if (Array.isArray(comments) && comments.length > 0) {
            e.stopPropagation();
            setSelectedComment({ path, idx });
        }
    }

    const isSelected = (path, idx) => {
        if (idx === undefined) {
            return path === selectedComment.path;
        }
        return path === selectedComment.path &&
            idx === selectedComment.idx;
    }

    // Build a flat list of elements for Pager
    const sectionElements = resume.sections.map((section, idx, arr) => {

        let position;
        if ((idx === 0 && section.type !== 'candidate') || idx === 1) {
            position = 'top';
        } else if (idx === arr.length - 1) {
            position = 'bottom';
        }

        switch (section.type) {
            case 'candidate':
                return sections.candidate({ data: resume, isSelected, onClick: handleClick, position })
            case 'jobs':
                return sections.jobs({ data: resume, isSelected, onClick: handleClick, position })
            case 'schools':
                return sections.education({ data: resume, isSelected, onClick: handleClick, position })
            case 'projects':
                return sections.projects({ data: resume, isSelected, onClick: handleClick, position })
            case 'certifications':
                return sections.certifications({ data: resume, isSelected, onClick: handleClick, position })
        }

        return sections.plain({ data: section.text, isSelected, onClick: handleClick, position, index: idx })
    });

    return (
        <>
            <Typography variant="h3" sx={{ mb: 4 }}>{resume.title}</Typography>
            <p className={styles.intro}>{resume.intro}</p>
            <CommentsHeader data={resume} isSelected={isSelected} onClick={handleClick} />
            <div className={styles.row}>
                <div>
                    <Pager>
                        {sectionElements}
                    </Pager>
                </div>
                <CommentsSidebar data={resume} isSelected={isSelected} onClick={handleClick} />
            </div>
        </>
    );

};

ResumeView.propTypes = {
    resume: PropTypes.object
};

export default ResumeView;
