import { useEffect, useState } from 'react';

import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Image from 'mui-image';
import Screenshots from '../images/screenshots.png';
import Button from './button';

const Hero = () => {

    const theme = useTheme();
    const [currentBenefitIdx, setCurrentBenefitIdx] = useState(0);

    const benefits = [
        <>start getting <i>job interviews</i></>,
        <>get better <i>compensation</i></>,
        <>work <i>remotely</i></>,
        <>get better <i>work-life balance</i></>,
        <>work with <i>newer technologies</i></>,
        <>get better <i>culture fit</i></>,
        <>get more <i>interesting projects</i></>,
        <><i>feel valued</i> in your new role</>,
        <>get <i>flexible</i> working hours</>,
        <>get more <i>supportive environment</i></>,
        <>reduce your <i>commute</i></>,
        <>advance your <i>career</i></>,
    ];

    useEffect(() => {
        const timerId = setTimeout(() => {
            setCurrentBenefitIdx((prevIndex) => (prevIndex + 1) % benefits.length);
        }, currentBenefitIdx === 0 ? 5000 : 1500);

        return () => clearTimeout(timerId);
    }, [currentBenefitIdx]);

    return (
        <Container sx={{ mt: { sm: '-4rem', sx: '-2rem' }, px: { sm: '24px', xs: '16px' } }}>
            <Box sx={{ fontFamily: 'sans-serif', position: 'relative' }}>
                <Box sx={{
                    width: { md: '700px', xs: '400px' },
                    height: { md: '700px', xs: '400px' },
                    borderRadius: '9999px',
                    backgroundColor: 'secondary.light',
                    position: 'absolute',
                    top: { lg: '-16rem', md: '16rem', xs: '18rem' },
                    right: { lg: '-20rem', md: '-5rem', xs: '-3.5rem' },
                    zIndex: -50,
                }} />
                <Box sx={{
                    display: 'grid',
                    gridTemplateColumns: { lg: 'repeat(12, minmax(0, 1fr))', xs: 'minmax(0, 1fr)' },
                    gap: { lg: 8 },
                    rowGap: { md: 16, xs: 18 },
                    pt: 8,
                    pb: 20,
                    alignItems: 'center',
                    position: 'relative',
                    borderBottom: 1,
                    borderColor: 'neutral.200'
                }}>
                    <Stack direction="column" alignItems="start" gap={10} columnGap={{ lg: 6 }}
                        sx={{
                            gridColumn: { lg: 'span 4 / span 4' }
                        }}
                    >
                        <Stack direction="column" spacing={4} sx={{
                            pr: { lg: 10, md: 24 },
                            position: 'relative'
                        }}>
                            <Typography component="h1" sx={{
                                typography: { md: 'h3', xs: 'h4' },
                                fontWeight: 600,
                                height: {
                                    lg: `calc(3 * ${theme.typography.h3.lineHeight})`,
                                    md: theme.typography.h3.lineHeight,
                                    xs: `calc(2 * ${theme.typography.h4.lineHeight})`
                                },
                                i: {
                                    color: 'primary.main'
                                }
                            }}>
                                {benefits[currentBenefitIdx]}
                            </Typography>
                            <Typography sx={{
                                typography: { md: 'body-lg', xs: 'body-md' },
                                color: 'neutral.700'
                            }}>
                                <p>
                                    The hiring process is broken and we&apos;re not going to fix it.
                                    We&apos;re going to help you win this game and get to the actual job.
                                </p>
                            </Typography>
                        </Stack>
                        <Button link="#features" label="Let's hack it" size="lg" />
                    </Stack>
                    <Stack direction="column" gap={10} sx={{
                        gridColumn: { lg: 'span 8 / span 8' },
                        position: 'relative'
                    }}>
                        <Image src={Screenshots} alt="Screenshots" sx={{ maxWidth: '800px' }} />
                    </Stack>
                </Box>
            </Box>
        </Container>
    );
}

export default Hero;