import { useState } from 'react';

import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import SmsIcon from '@mui/icons-material/Sms';

import FeedbackModal from './FeedbackModal';

const FeedbackButton = () => {

    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
    }

    return (
        <>
            <Tooltip title="Help & Feedback" placement="left">
                <IconButton
                    color="primary"
                    onClick={handleOpen}
                    size="large"
                    sx={{
                        position: 'fixed',
                        bottom: (theme) => theme.spacing(3),
                        right: (theme) => theme.spacing(3),
                        zIndex: 1000,
                        borderRadius: '9999px',
                        backgroundColor: (theme) => theme.palette.primary.main,
                        ':hover': {
                            backgroundColor: (theme) => theme.palette.primary.main
                        },
                        color: 'white'
                    }}
                >
                    <SmsIcon />
                </IconButton>
            </Tooltip>
            <FeedbackModal open={open} onClose={handleClose} />
        </>
    );
}

export default FeedbackButton;
