import Field from '../Field';
import styles from '../ResumeView.module.css';

const candidate = (params) => {

    const { data, isSelected, onClick, position } = params;

    const elements = [
        <div key='candidate-title' className={styles.title}>
            <div>
                <Field data={data} path='candidate.name' isSelected={isSelected} onClick={onClick} />
            </div>
            <Field data={data} path='candidate.position' isSelected={isSelected} onClick={onClick} />
        </div>,
        <div key='candidate-title-gap' className={styles.gap16}></div>,
        <div key='candidate-location' className={styles.contacts}>
            <Field data={data} path='candidate.location' isSelected={isSelected} onClick={onClick} />
        </div>,
        <div key='candidate-location-gap' className={styles.gap8}></div>,
        <div key='candidate-phone' className={styles.contacts}>
            <Field data={data} path='candidate.phone' isSelected={isSelected} onClick={onClick} />
        </div>,
        <div key='candidate-phone-gap' className={styles.gap8}></div>,
        <div key='candidate-email' className={styles.contacts}>
            <Field data={data} path='candidate.email' isSelected={isSelected} onClick={onClick} />
        </div>
    ];

    if (position !== 'bottom') {
        elements.push(
            <div key='candidate-gap' className={styles.gap32}></div>
        )
    }

    return elements;
}

export default candidate;