import { useState } from 'react';
import PasswordResetForm from '../components/PasswordResetForm';
import authService from '../services/authService';

const PasswordResetPage = () => {
    const [newPassword, setNewPassword] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        authService.resetPassword({ newPassword })
    };

    return (
        <div>
            <PasswordResetForm onReset={handleSubmit} onPasswordChange={setNewPassword} />
        </div>
    );
}

export default PasswordResetPage;