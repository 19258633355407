import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useState } from 'react';
import { Link, Navigate, useLocation } from 'react-router-dom';

import { useAuth } from '../auth';
import GoogleSignInButton from '../components/GoogleSignInButton';
import RegisterForm from '../components/RegisterForm';

const RegisterPage = () => {

    const location = useLocation();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null);
    const origin = location.state?.from?.pathname || '/';

    const auth = useAuth();

    const handleSubmit = async (e) => {
        e.preventDefault();

        setError(null);
        const result = await auth.onRegister({ email, password });
        if (result.error) {
            setError(result.error);
        }
    };

    const handleGoogleSignIn = async () => {
        const fullOrigin = window.location.origin + origin;

        setError(null);
        const result = await auth.onGoogleSignIn(fullOrigin);
        if (result.error) {
            setError(result.error);
        }
    }

    if (auth.user) {
        return <Navigate to={origin} replace />;
    }

    // TODO: split login and register errors
    return (
        <Container>
            <Typography variant="h4" sx={{ textAlign: 'center', pb: 8 }}>Create your free account</Typography>
            <Container sx={{ width: 400, maxWidth: '100%' }}>
                <Stack spacing={6}>
                    <GoogleSignInButton onGoogleSignIn={handleGoogleSignIn} />
                    <Divider>or register with e-mail</Divider>
                    <RegisterForm
                        onRegister={handleSubmit}
                        onEmailChange={setEmail}
                        onPasswordChange={setPassword}
                        loading={auth.isLoading} />
                    {error && <Alert severity="error">{error}</Alert>}
                    <Button component={Link} to="/login">Log in</Button>
                </Stack>
            </Container>
        </Container>
    );
}

export default RegisterPage;