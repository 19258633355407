import PropTypes from 'prop-types';
import { useState } from 'react';
import { Link } from 'react-router-dom';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

const ResumeCard = ({ item, onDelete }) => {

    const [menuAnchor, setMenuAnchor] = useState(null);
    const menuOpen = Boolean(menuAnchor);

    const handleMenuOpen = (event) => {
        setMenuAnchor(event.currentTarget);
    };

    const handleMenuClose = () => {
        setMenuAnchor(null);
    };

    const handleDelete = () => {
        setMenuAnchor(null);
        setDialogOpen(true);
    };

    const [dialogOpen, setDialogOpen] = useState(false);

    const handleDialogClose = () => {
        setDialogOpen(false);
    };

    const handleConfirmDelete = (e) => {
        setDialogOpen(false);
        onDelete(e);
    }

    return <>
        <Card
            sx={{
                width: 275,
                height: 390,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between'
            }}
        >
            <CardHeader
                action={
                    <IconButton id="menu-button" onClick={handleMenuOpen}>
                        <MoreVertIcon />
                    </IconButton>
                }
                title={item.title ?? 'Untitled resume'}
            />
            <CardContent sx={{ flexGrow: 1 }}>
                <Typography component="div">
                    {item.status}
                </Typography>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%'
                }}
                >
                    {item.status === 'processing' &&
                    <HourglassBottomIcon color="primary" sx={{ fontSize: 40 }} />}
                </Box>
        </CardContent>
        <CardActions>
            <Button component={Link} to={`/resume/${item.resumeId}`} disabled={item.status !== 'ready'}>View</Button>
        </CardActions>
    </Card >
        <Menu
            id="menu"
            anchorEl={menuAnchor}
            open={menuOpen}
            onClose={handleMenuClose}
            MenuListProps={{
                'aria-labelledby': 'menu-button',
            }}
            sx={{ width: 200, maxWidth: '100%' }}
        >
            <MenuItem onClick={handleDelete}>Delete</MenuItem>
        </Menu>
        <Dialog
            open={dialogOpen}
            onClose={handleDialogClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {"Delete resume?"}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Are you sure you want to delete this resume? This action cannot be undone!
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleDialogClose} autoFocus>Cancel</Button>
                <Button onClick={handleConfirmDelete}>
                    Delete
                </Button>
            </DialogActions>
        </Dialog>
    </>
}

ResumeCard.propTypes = {
    item: PropTypes.object,
    onDelete: PropTypes.func
};

export default ResumeCard;

