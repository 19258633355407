import PropTypes from 'prop-types';
import MuiButton from '@mui/material/Button';

const Button = ({ label, link, size }) => (
    <MuiButton
        component="a"
        href={link}
        variant="contained"
        color="primary"
        sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '0.375rem',
            bgcolor: 'primary.600',
            color: 'white',
            fontWeight: 'bold',
            px: 4,
            py: size === 'lg' ? 3 : 2.5,
            typography: size === 'lg' ? 'body-md' : 'body-sm',
            textTransform: 'none',
        }}
    >
        {label}
    </MuiButton>
);

Button.propTypes = {
    label: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
    size: PropTypes.string,
};

export default Button;