import PropTypes from 'prop-types';

import Comment from './Comment';

import styles from './ResumeView.module.css';

const CommentsHeader = (props) => {

    const { data, isSelected, onClick } = props;

    return (
        <div className={styles.commentsHeader}>
            <Comment data={data} path='' isSelected={isSelected} onClick={onClick} />
            <Comment data={data} path='candidate' isSelected={isSelected} onClick={onClick} />
            <Comment data={data} path='jobs' isSelected={isSelected} onClick={onClick} />
            <Comment data={data} path='schools' isSelected={isSelected} onClick={onClick} />
            <Comment data={data} path='certifications' isSelected={isSelected} onClick={onClick} />
        </div>
    );

}

CommentsHeader.propTypes = {
    data: PropTypes.object,
    isSelected: PropTypes.func,
    onClick: PropTypes.func
}

export default CommentsHeader;