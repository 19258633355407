import PropTypes from "prop-types";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from '@mui/material/Typography';
import Image from "mui-image";

const FeatureItem = ({ icon, title, description }) => {
    return (
        <Stack
            direction={{ sm: "column", xs: "row" }}
            alignSelf="start"
            alignItems={{ md: 'center' }}
            gap={6}
            sx={{
                width: { md: 'calc(33% - 2rem)', sm: '40%', xs: '100%' },
                p: { sm: 6, xs: 4 },
                py: 6
            }}
        >
            <Box sx={{
                display: 'flex',
                flexBasis: 0,
                flexGrow: 1
            }}>
                <Image
                    src={icon}
                    alt="Feature Icon"
                    sx={{
                        boxShadow: '0px 1px 8px 0px rgba(0,0,0,0.12)'
                    }}
                />
            </Box>
            <Stack
                direction="column"
                gap={2}
                justifyContent={{ sm: 'start', xs: 'center' }}
                alignItems={{ sm: 'center', xs: 'left' }}
                textAlign={{ sm: 'center', xs: 'left' }}
                sx={{
                    flexBasis: 0,
                    flexGrow: 1,
                }}
            >
                <Typography component="p" sx={{
                    typography: { md: 'h6', xs: 'body-xl' },
                    fontWeight: 600
                }}>
                    {title}
                </Typography>
                <Typography sx={{
                    typography: { md: 'body-md', xs: 'body-sm' }
                }} color="neutral.700">
                    {description}
                </Typography>
            </Stack>
        </Stack>
    );
};

FeatureItem.propTypes = {
    icon: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
};

export default FeatureItem;