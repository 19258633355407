import { useState } from 'react';
import PasswordResetRequestForm from '../components/PasswordResetRequestForm';

const PasswordResetRequestPage = () => {
    const [email, setEmail] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        await fetch(`${process.env.REACT_APP_API_URL}/reset-password/request`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ email }),
        });
    };

    return (
        <div>
            <PasswordResetRequestForm onRequestReset={handleSubmit} onEmailChange={setEmail} />
        </div>
    );
}

export default PasswordResetRequestPage;