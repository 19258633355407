import PropTypes from 'prop-types';
import React, { useLayoutEffect, useRef, useState } from 'react';
import { createRoot } from 'react-dom/client';
import styles from './ResumeView.module.css';

const Pager = ({ children }) => {
    const [pagesList, setPagesList] = useState([]);
    const pageTemplateRef = useRef(null);

    useLayoutEffect(() => {

        if (!pageTemplateRef.current) {
            return;
        }

        if (pagesList.length > 0) {

            const elements = React.Children.toArray(children);
            let eIdx = 0;

            let updatedList = [];

            for (let i = 0; i < pagesList.length; i++) {
                updatedList[i] = [];
                for (let j = 0; j < pagesList[i].length; j++) {
                    updatedList[i][j] = elements[eIdx++];
                }
            }

            setPagesList(updatedList);
            return;
        }

        pageTemplateRef.current.innerHTML = '';

        const pageHeight = pageTemplateRef.current.clientHeight;
        let pageElements = [];
        let pages = [];

        const paginate = async () => {

            let pageRoot = createRoot(pageTemplateRef.current);

            for (const child of React.Children.toArray(children)) {

                pageRoot.render(<>{[...pageElements, child]}</>);

                // Wait for the page to render and measure content height
                await new Promise((resolve) => setTimeout(resolve, 0));
                const contentHeight = pageTemplateRef.current.scrollHeight;

                if (contentHeight > pageHeight) {
                    pages.push(pageElements); // TODO: fix potential endless loop in case when pageElements is empty
                    pageElements = [];
                    pageRoot.unmount();
                    pageRoot = createRoot(pageTemplateRef.current);
                }

                pageElements.push(child);

            }

            if (pageElements.length > 0) {
                pages.push(pageElements);
            }
            setPagesList(pages);

        }

        paginate();

    }, [children]);

    return (
        <>
            <div style={{ position: 'absolute', left: '-9999px', top: '-9999px' }}>
                <div className={styles.page}>
                    <div ref={pageTemplateRef} className={styles.resume}>
                    </div>
                </div>
            </div>
            {pagesList.map((pageContent, pageIndex) => {
                return <div key={pageIndex} className={styles.page}>
                    <div className={styles.resume}>
                        {pageContent}
                    </div>
                </div>
            })}
        </>
    );
};

Pager.propTypes = {
    children: PropTypes.node
};

export default Pager;