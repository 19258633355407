import PropTypes from 'prop-types';

const StripePaymentButton = ({ buyButtonId, publishableKey, clientReferenceId, customerEmail }) => {

    return (
        <stripe-buy-button
            buy-button-id={buyButtonId}
            publishable-key={publishableKey}
            client-reference-id={clientReferenceId}
            customer-email={customerEmail}
        >
        </stripe-buy-button>
    );

}

StripePaymentButton.propTypes = {
    buyButtonId: PropTypes.string,
    publishableKey: PropTypes.string,
    clientReferenceId: PropTypes.string,
    customerEmail: PropTypes.string
}

export default StripePaymentButton;