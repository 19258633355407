import styles from '../ResumeView.module.css';

const plain = (props) => {

    const { data, position, index } = props;

    const elements = [
        <div key={`plain-${index}`} className={styles.field}>{data}</div>
    ]

    if (position !== 'bottom') {
        elements.push(
            <div key={`plain-gap-${index}`} className={styles.gap32}></div>
        )
    }

    return elements;

}

export default plain;