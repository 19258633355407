import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import PropTypes from 'prop-types';

import InputFileUpload from './InputFileUpload';
import ResumeCard from './ResumeCard';

const ResumeList = (props) => {

    const { items, onUploadResumeClick, onUploadResume, isFileUploading, onCopyPasteResume, onDeleteResume } = props;

    items.sort((a, b) => b.resumeId - a.resumeId);

    return <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: 4 }}>
        {items.map(item =>
            <ResumeCard
                key={item.resumeId}
                item={item}
                onDelete={(e) => { onDeleteResume(e, item.resumeId) }}
            />
        )}
        <Card
            variant="outlined"
            sx={{
                minWidth: 275,
                minHeight: 390,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                borderStyle: 'dashed'
            }}
        >
            <Stack gap={4}>
                <InputFileUpload
                    name="resume"
                    title="Upload PDF"
                    loading={isFileUploading}
                    accept=".pdf"
                    onClick={onUploadResumeClick}
                    onChange={onUploadResume}
                />
                <Button variant="outlined" onClick={onCopyPasteResume}>Copy and paste</Button>
            </Stack>
        </Card>
    </Box>
}

ResumeList.propTypes = {
    items: PropTypes.array,
    onUploadResumeClick: PropTypes.func,
    onUploadResume: PropTypes.func,
    isFileUploading: PropTypes.bool,
    onCopyPasteResume: PropTypes.func,
    onDeleteResume: PropTypes.func
};

export default ResumeList;